// src/App.js
import React, { useState, useEffect } from 'react';
import { VoiceAssistantProvider, FloatingAssistant, useVoiceAssistantContext } from './FloatingAssistant';
import './App.css';

function LandingContent() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [livekitConfig, setLivekitConfig] = useState({ token: null, serverUrl: null });
  const { openAssistant } = useVoiceAssistantContext();

  const images = ['tfb1.webp', 'tfb2.webp', 'tfb3.webp', 'tfb4.webp'];

  // Image cycling effect with 6-second interval
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prev) => (prev + 1) % images.length);
    }, 6000); // Changed to 6 seconds

    return () => clearInterval(timer);
  }, []);

  // LiveKit token fetch
  useEffect(() => {
    async function fetchLivekitToken() {
      try {
        const response = await fetch('https://trustfund.baby/api/livekit-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: 'user-' + Math.random().toString(36).substr(2, 9)
          })
        });

        if (!response.ok) {
          throw new Error('Failed to fetch LiveKit token');
        }

        const data = await response.json();
        setLivekitConfig({
          token: data.token,
          serverUrl: data.serverUrl
        });
      } catch (error) {
        console.error('Error fetching LiveKit token:', error);
      }
    }

    fetchLivekitToken();
  }, []);

  return (
    <div className="landing-page">
      <div className="gradient-bg">
        <div className="circles">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="circle"></div>
          ))}
        </div>
      </div>
      
      <div className="content">
        <img 
          src={images[currentImageIndex]} 
          alt={`TFB ${currentImageIndex + 1}`}
          className="featured-image"
        />
      </div>

      {livekitConfig.token && (
        <FloatingAssistant 
          token={livekitConfig.token}
          serverUrl={livekitConfig.serverUrl}
          showFloatingButton={false}
        />
      )}
      
      <button 
        className="talk-button cta-button"
        onClick={openAssistant}
      >
        Talk to Me
      </button>
    </div>
  );
}

function App() {
  return (
    <VoiceAssistantProvider>
      <LandingContent />
    </VoiceAssistantProvider>
  );
}

export default App;